import chatModule from '../chatModule';

function userSettingsService(
    $q,
    httpService,
    $log,
    apiParamsInitialization
) {
  'ngInject';

  return {
    getChatUserSettings() {
      const params = {
        requestedUserSettings: 'whoCanChatWithMeInApp'
      };
      return httpService.httpGet(apiParamsInitialization.apiSets.getUserSettings, params);
    }
  };
}

chatModule.factory('userSettingsService', userSettingsService);

export default userSettingsService;
