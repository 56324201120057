import chatModule from '../chatModule';
import { EventStream } from 'Roblox';

function analyticsService($log) {
  'ngInject';

  const EventTracker = window.EventTracker;

  return {
    sendEvent(eventName, eventProperties) {
      if (!EventStream) {
        return;
      }
      EventStream.SendEventWithTarget(
        eventName,
        'WebChatEventContext', // Context; not currently used
        eventProperties,
        EventStream.TargetTypes.WWW
      );
    },
    incrementCounter(counterName) {
      if (!EventTracker) {
        return;
      }
      EventTracker.fireEvent(counterName);
    }
  };
}

chatModule.factory('analyticsService', analyticsService);

export default analyticsService;
